import React from 'react'
import Title from '../Title'

const ExperienceCard = ({ duration, role, company, location, description }) => (
  <div className="grid w-full card rounded-xl place-items-center mb-4 lg:mb-0">
    <div className="flex flex-col xl:flex-row w-full items-center">
      <div className="w-full xl:w-2/5 card rounded-box flex justify-center text-3xl xl:text-5xl pl-4 xl:pl-10 mb-4 xl:mb-0">
        {duration}
      </div>
      <div className="w-full xl:w-3/5 card rounded-box p-4 xl:p-8">
        <div className="flex flex-col">
          <h2 className="text-lg xl:text-2xl">{role} @ <span className="text-[#ED6A5A]">{company}</span></h2>
          <h2 className="text-lg">{location}</h2>
          <p className="text-sm xl:text-base">{description}</p>
        </div>
      </div>
    </div>
  </div>
);

const Experience = () => {
  const experiences = [
    {
      duration: "Dec '24 - Present",
      role: "Software Engineer intern",
      company: "cstoreiq",
      location: "Huntsville, AL",
      description: "Developing an ERP system for warehouse management to streamline inventory, logistics, and operational workflows. Primarily engaged in developing enterprise-grade backend architecture and with optimized database performance for high-volume transaction handling."
    },
    {
      duration: "Jun '24 - Dec '24",
      role: "Full Stack Engineer",
      company: "Epics at ASU - shoptaki",
      location: "New York, NY",
      description: "Working on a smartchain for Identity identification. Primarily engaged in development of backend system to create robust and accurate Facial and voice recognition with Liveliness detection."
    },
    {
      duration: "Jan '22 - Jun '22",
      role: "Software Engineer Intern",
      company: "Sperotel Technologies",
      location: "Ahmedabad, Gujarat, India",
      description: "I contributed significantly to developing an interactive dashboard for real-time UPS system monitoring, effectively minimizing breakdowns. Additionally, I played a role in creating a server program to improve data accuracy and overall system performance."
    },
    {
      duration: "Jun '21 - Nov '21",
      role: "Frontend Developer Intern",
      company: "Cloud Ladder Consulting",
      location: "Irving, TX",
      description: "Worked on a B2B service provider website, made changes in UI that resulted in significant user activity, performed white box testing of the whole website, and contributed to the development of the chatbot \"Cloudy\"."
    }
  ];

  return (
    <div className="w-full max-w-7xl mx-auto">
      <Title Title={'Experience'} />
      <div className="flex flex-col w-full px-4 xl:px-10 mt-4 text-center xl:text-left space-y-4">
        {experiences.map((exp, index) => (
          <React.Fragment key={index}>
            <div className="divider" />
            <ExperienceCard {...exp} />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Experience;